import { render, staticRenderFns } from "./Lead.vue?vue&type=template&id=32d3b4bf&scoped=true&lang=pug&"
import script from "./Lead.vue?vue&type=script&lang=js&"
export * from "./Lead.vue?vue&type=script&lang=js&"
import style0 from "./Lead.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./Lead.vue?vue&type=style&index=1&id=32d3b4bf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32d3b4bf",
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QStepper from 'quasar/src/components/stepper/QStepper.js';
import QStep from 'quasar/src/components/stepper/QStep.js';import Scroll from 'quasar/src/directives/Scroll.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QBtn,QToolbar,QImg,QStepper,QStep});qInstall(component, 'directives', {Scroll});
